import { InsuranceType } from "@/dto/archive/insurance/InsuranceType";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import InsurancePayment from "@/dto/payment/InsurancePayment";
import CreateCertificate from "@/dto/archive/insurance/CreateCertificate";
import CreateOtherDocument from "@/dto/archive/insurance/CreateOtherDocument";

export default class CreateInsurancePayload {

    companyId!: number;
    insurerName: string | null = null;
    policyNumber: string | null = null;
    type!: InsuranceType;
    startDate: Date | null = null;
    endDate: Date | null = null;
    branchesIds: Array<number> | [] = [];
    schedule: Array<InsurancePayment> | [] = [];
    certificates: Array<CreateCertificate> | [] = [];
    otherDocuments: Array<CreateOtherDocument> | [] = [];
    scheduleDocuments: Array<FileMetaDTO> | [] = [];
    insurerLetters: Array<FileMetaDTO> | [] = [];
    historical = false;

    constructor(init?: Partial<CreateInsurancePayload>) {
        Object.assign(this, init);
    }

}
