






















































































































































































































































































































import { Component, Prop } from "vue-property-decorator";
import { mixins } from 'vue-class-component';
import PortalCollapse from "@/components/request/w9cr/PortalCollapse.vue";
import DocumentService from "@/services/DocumentService";
import { processError } from "@/utils/ComponentUtils";
import { namespace } from "vuex-class";
import InsuranceFilter from "@/dto/archive/insurance/InsuranceFilter";
import { InsuranceType } from "@/dto/archive/insurance/InsuranceType";
import InsuranceDTO from "@/dto/archive/insurance/InsuranceDTO";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import PortalDate from "@/components/common/PortalDate.vue";
import PortalSelect from "@/components/common/PortalSelect.vue";
import CreateInsurancePayload from "@/dto/archive/insurance/CreateInsurancePayload";
import BranchDto from "@/components/profile/branches/BranchDtos";
import CompanyService from "@/services/CompanyService";
import SelectOption from "@/components/common/SelectOption";
import PortalInput from "@/components/common/PortalInput.vue";
import InsurancePayment from "@/dto/payment/InsurancePayment";
import GetRandomId from "@/mixins/getRandomId";
import { WorkspaceType } from "@/dto/auth/Workspace";
import CreateCertificate from "@/dto/archive/insurance/CreateCertificate";
import CreateOtherDocument from "@/dto/archive/insurance/CreateOtherDocument";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";

const AppModule = namespace("App");

@Component<InsuranceList>({
  components: {
    PortalCheckbox,
    PortalInput,
    PortalDate,
    PortalCollapse,
    PortalSelect,
    SingleFileHolder,
  },
  computed: {
    SelectOption() {
      return SelectOption
    }
  },
  watch: {
    type() {
      this.updateFilterAndPayload();
      this.loadInsurances();
    },
  }
})
export default class InsuranceList extends mixins(GetRandomId) {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  @Prop()
  private type!: InsuranceType;

  insurancesList: Array<InsuranceDTO> | [] = [];
  createMode = false;

  filter = new InsuranceFilter();
  payload = new CreateInsurancePayload();
  payment = new InsurancePayment();
  certificate = new CreateCertificate();
  otherDocument = new CreateOtherDocument();
  scheduleDocument: FileMetaDTO | null = null;
  insurerLetter: FileMetaDTO | null = null;
  branches: Array<BranchDto> | [] = [];
  private isValidSchedule = true;
  private isValidScheduleDocuments = true;
  private isValidScheduleDocument = true;
  private isValidCertificates = true;
  private isValidCertificate = true;
  private isValidOtherDocument = true;
  private isValidInsurerLetter = true;

  mounted(): void {
    this.init();
  }

  init(){
    if (this.$wss.getCurrent.type === WorkspaceType.COMPANY ) {
      this.updateFilterAndPayload();
      this.loadBranchesAndInsurances();
    }
  }

  updateFilterAndPayload(): void {
    const companyId = this.$wss.getCurrent.id;
    this.payload.companyId = companyId;
    this.payload.type = this.type;
    this.filter.companyId = companyId;
    this.filter.type = this.type;
  }

  getTitle(insurance: InsuranceDTO): string {
    const today = new Date().getUTCDate();
    const endDay = new Date(insurance.endDate).getUTCDate();
    if (endDay <= today) {
      return `Insurer name: ${insurance.insurerName}. Policy number: ${insurance.policyNumber}. From ${this.$dateUtils.formatDateToAmericanFormat(insurance.startDate)}, expired on ${this.$dateUtils.formatDateToAmericanFormat(insurance.endDate)}.`
    }
    return `Insurer name: ${insurance.insurerName}. Policy number: ${insurance.policyNumber}. From ${this.$dateUtils.formatDateToAmericanFormat(insurance.startDate)}, expires on ${this.$dateUtils.formatDateToAmericanFormat(insurance.endDate)}.`
  }

  loadInsurances(): void {
    this.startLoading();
    DocumentService.getInsurances(this.filter).then(
      resp => {
        this.insurancesList = resp.data;
        this.stopLoading();
      },
      error => {
        processError(error, this);
        this.stopLoading();
      }
    )
  }

  private loadBranchesAndInsurances() {
    this.startLoading();
    Promise.all([
      CompanyService.getHeadQuarter(),
      CompanyService.getBranches(this.$wss.getCurrent.id),
      DocumentService.getInsurances(this.filter),
    ]).then(
      resArr => {
        let branches: Array<BranchDto> = [];
        if (resArr[0].data) {
          branches.push(resArr[0].data)
        }
        branches.push(...resArr[1].data)
        this.branches = branches;
        this.insurancesList = resArr[2].data;
        this.stopLoading();
      },
      err => {
        this.stopLoading();
        processError(err, this);
      }
    )
  }

  save(): void {
    this.$validator.validate('main-form.*').then((isValid) => {
      this.isValidSchedule = this.payload.historical || !!this.payload.schedule.length;
      this.isValidScheduleDocuments = this.payload.historical || !!this.payload.scheduleDocuments.length;
      this.isValidCertificates = this.payload.historical || !!this.payload.certificates.length;

      if (isValid && this.isValidSchedule && this.isValidScheduleDocuments && this.isValidCertificates) {
        this.startLoading();
        DocumentService.addInsurance(this.payload).then(
          ok => {
            this.createMode = false;
            this.stopLoading();
            this.loadInsurances();
            this.init();
          },
          err => {
            processError(err, this);
            this.stopLoading();
          }
        )
      }
    })
  }

  addPayment(): void {
    this.$validator.validate('schedule-form.*').then((isValid) => {
      if (isValid) {
        this.payload.schedule.push(this.payment as never);
        this.payment = {
          date: null,
          amount: null,
        };
        this.isValidSchedule = !!this.payload.schedule.length;
      }
    })
  }

  addCertificate(): void {
    this.$validator.validate('certificates-form.*').then((isValid) => {
      this.isValidCertificate = !!this.certificate.file;
      if (isValid && this.isValidCertificate) {
        this.payload.certificates.push(this.certificate as never);
        this.certificate = {
          file: null,
          holder: null,
        };
        this.isValidCertificates = !!this.payload.certificates.length;
      }
    })
  }

  addOtherDocument(): void {
    this.$validator.validate('other-documents-form.*').then((isValid) => {
      this.isValidOtherDocument = !!this.otherDocument.file;
      if (isValid && this.isValidOtherDocument) {
        this.payload.otherDocuments.push(this.otherDocument as never);
        this.otherDocument = {
          file: null,
          comment: null,
        };
      }
    })
  }

  addScheduleDocument(): void {
    this.$validator.validate('schedule-documents-form.*').then((isValid) => {
      this.isValidScheduleDocument = !!this.scheduleDocument;
      if (isValid && this.isValidScheduleDocument) {
        this.payload.scheduleDocuments.push(this.scheduleDocument as never);
        this.scheduleDocument = null;
        this.isValidScheduleDocuments = !!this.payload.scheduleDocuments.length;
      }
    })
  }

  addInsurerLetter(): void {
    this.$validator.validate('insurer-letters-form.*').then((isValid) => {
      this.isValidInsurerLetter = !!this.insurerLetter;
      if (isValid && this.isValidInsurerLetter) {
        this.payload.insurerLetters.push(this.insurerLetter as never);
        this.insurerLetter = null;
      }
    })
  }

}
